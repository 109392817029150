* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

hr.solid {
  border-top: 3px solid #bbb;
}


body, html {
  background-color: #ffffff;
  color: #000000;
  overflow-x: hidden;

  font-family: 'Roboto', sans-serif;
  font-size: 24px;
}

h1,
h4 {
    margin: .5em;
}
